// Default theme colors
$theme-colors: (
  "coolblack": #090a0b,
  "spacegrey": #353535,
  "snowwhite": #ffffff,
);

// Default brand colors
$brand-colors: (
  // "orangered": #ff5100,
  // "greatgold": #f2cb05,
  "greenblue": #389092,
  "greenlight": #9ad1aa,
  "greendark": #77C18D,
  "greendarker": #54B170
);

$theme-name: "";
$brand-name: "greendarker";
$theme-color: map-get($theme-colors, "snowwhite");
// $brand-color: map-get($brand-colors, "orangered");
$brand-color: map-get($brand-colors, "greenblue");

@if map-has-key($theme-colors, $theme-name) {
  $theme-color: map-get($theme-colors, $theme-name);
} @else if str-index($theme-name, "#") == 1 {
  $theme-color: #ffffff;
}

@if map-has-key($brand-colors, $brand-name) {
  $brand-color: map-get($brand-colors, $brand-name);
} @else if str-index($brand-name, "#") == 1 {
  $brand-color: greendarker;
}

$content-width: 920px;

// Click to top theme
$click-to-top-light-background-color: white;
$click-to-top-light-color: #454545;
$click-to-top-dark-background-color: #34323d;
$click-to-top-dark-color: #bbb;

// Selection styles
$selection-color: unquote("");
$selection-background-color: unquote("");

@if $selection-color == "" {
  $selection-color: inherit;
}
@if $selection-background-color == "" {
  $selection-background-color: rgba(invert($brand-color), 0.3);
}

@import "yat";
